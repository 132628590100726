<template>
  <div class="flex-w-col">
    <!-- bPath='/learn/csharp/12'
    fPath='/learn/csharp/14' -->
    <LearnHeader
      title='Phone Number'
    />
  </div>
</template>

<script>
import LearnHeader from '@/components/LearnHeader.vue'

export default {
  components: { LearnHeader },
}
</script>


<style scoped>

</style>
